import chroma from 'chroma-js/index'
import { chromaColorToHslValues, setRootProperty } from './helpers'

(() => {
	/* eslint no-restricted-syntax: ["error"] */

	const devConsole = null /* || document.createElement('pre')
	devConsole.classList.add('__console')
	document.querySelector('body').append(devConsole) */

	const getChapterId = chapter => chapter.className.replace(/.*--/, '')

	const hsl2chroma = hsl => chroma(`hsl(${hsl.replace(/deg/, '')})`)

	const getChapterColor = chapter => getComputedStyle(chapter).getPropertyValue('--background-color-hsl').replace(/^\s*/, '')

	const buildChapterTransitionColors = (a, b, fallback) =>
		chroma
			.scale([ hsl2chroma(getChapterColor(a) || fallback), hsl2chroma(getChapterColor(b) || fallback) ])
			.mode('lab')
			.colors(10, null)
			.map(color => chromaColorToHslValues(color))

	const buildChapterColors = (chapters) => {
		const table = {}
		let prevChapter
		let lastDefinedColor
		for (const chapter of chapters) {
			const color = getChapterColor(chapter) || lastDefinedColor
			table[getChapterId(chapter)] = color
			if (prevChapter) {
				table[`${getChapterId(prevChapter)}/${getChapterId(chapter)}`]
					= buildChapterTransitionColors(prevChapter, chapter, lastDefinedColor || null)
			}
			lastDefinedColor = color
			prevChapter = chapter
		}
		return table
	}

	const header = document.querySelector('.site-header')
	const fixedLogo = header.querySelector('.logo-dreckschanke')
	fixedLogo.classList.add('hidden')
	fixedLogo.style.display = 'block'

	const chapters = Array.from(document.querySelectorAll('.chapter'))

	const chapterColors = buildChapterColors(chapters)

	for (const chapter of chapters) {
		chapter.style.setProperty('--background-color-hsl', 'var(--dynamic-background-color-hsl)')
	}

	const chapterBounding = (chapter) => {
		const rect = chapter.getBoundingClientRect()
		const visible = rect.y < window.innerHeight && rect.bottom > 0
		return {
			top:       rect.y,
			bottom:    rect.bottom,
			isVisible: visible
		}
	}

	const calculateTransStep = top => Math.round( (window.innerHeight - top) / (window.innerHeight / 9))

	const calculateColor = () => {
		const areas = []
		let top = null
		for (const chapter of chapters) {
			const bounding = chapterBounding(chapter)
			if (bounding.isVisible) {
				areas.push(getChapterId(chapter))
				top = bounding.top
			}
		}
		const transId = areas.join('/')
		const transStep = areas.length === 2 ? calculateTransStep(top) : null

		if (devConsole) {
			devConsole.innerHTML = ` transId: ${transId}\n transStep: ${transStep}\n top: ${top}\n`
		}

		if (! chapterColors[transId] || null) {
			return null
		}

		return transStep === null ? chapterColors[transId] : chapterColors[transId][transStep]
	}

	let ticking = false
	let logoHidden = true
	let bgColor
	window.addEventListener('scroll', () => {
		if (! ticking) {
			window.requestAnimationFrame(() => {

				bgColor = calculateColor()

				// update dynamic background
				setRootProperty('--dynamic-background-color-hsl', bgColor)

				// show/hide fixed logo
				if (chapterBounding(chapters[0]).top < -window.visualViewport.height) {
					if (logoHidden) {
						fixedLogo.classList.remove('hidden')
						logoHidden = false
					}
				} else if (! logoHidden) {
					fixedLogo.classList.add('hidden')
					logoHidden = true
				}

				// inverse header (language switcher) text color & fixed logo
				if (chroma.contrast(hsl2chroma(bgColor), '#000') < 1.66) {

					// ToDo: invert if header is above last dark chapter & photos slider
					header.classList.add('inverted')

				} else {
					header.classList.remove('inverted')
				}

				ticking = false
			})
			ticking = true
		}
	})
})()
